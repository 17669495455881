module controllers {
    export module costing {
        interface ICostingDetailScope extends ng.IScope {
        }

        interface ICostingDetailItems {
            costingModel: interfaces.costing.ICostingModel,
            exchangeRateDescription: string,
            costingDetailList: ng.resource.IResourceArray<interfaces.costing.ICostingLineModel>,
        };

        export class costingDetailModalCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', 'costModelService', "$uibModalInstance", "items"];

            public costingModel: interfaces.costing.ICostingModel;
            public exchangeRateDescription: string;
            public costingDetailList: ng.resource.IResourceArray<interfaces.costing.ICostingLineModel>;

            constructor(private $scope: ICostingDetailScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private costModelService: interfaces.costing.ICostModelService,
                private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, items: ICostingDetailItems) {

                this.costingModel = items.costingModel;
                this.exchangeRateDescription = items.exchangeRateDescription;
                this.costingDetailList = items.costingDetailList;
            }

            ok() {
                this.$uibModalInstance.close();
            }
        }

        angular.module("app").controller("costingDetailModalCtrl", controllers.costing.costingDetailModalCtrl);
    }
}